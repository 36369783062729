import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  api: [],
};

export const apiKeySlice = createSlice({
  name: "auth/apiKey",
  initialState,
  reducers: {
    setAPIKey: (_, action) => action.payload.data,
  },
});

export const { setAPIKey } = apiKeySlice.actions;

export default apiKeySlice.reducer;
