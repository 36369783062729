import { combineReducers } from "@reduxjs/toolkit";
import session from "./sessionSlice";
import user from "./userSlice";
import apiKey from "./apiKeySlice";

const reducer = combineReducers({
  session,
  user,
  apiKey,
});

export default reducer;
