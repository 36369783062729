const appConfig = {
  apiPrefix: "/api",
  authenticatedEntryPath: "/home",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/",
  enableMock: false,
  apiEndpoint: "https://mservice.khongtuquynh.com/api",
};

export default appConfig;
